@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/Pretendard-Regular.otf");
  src: local("Pretendard"), local("Pretendard-Regular.otf"),
    url("../assets/font/Pretendard-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/font/Pretendard-Medium.otf");
  src: local("Pretendard"), local("Pretendard-Medium.otf"),
    url("../assets/font/Pretendard-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/font/Pretendard-SemiBold.otf");
  src: local("Pretendard"), local("Pretendard-SemiBold.otf"),
    url("../assets/font/Pretendard-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/font/Pretendard-Bold.otf");
  src: local("Pretendard"), local("Pretendard-Bold.otf"),
    url("../assets/font/Pretendard-Bold.otf") format("opentype");
}
